var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('div',{staticClass:"pa-3"},[_vm._l((this.data),function(item){return _c('div',{key:(item || {}).id,on:{"click":function($event){$event.stopPropagation();return _vm.onClickBtn(item.link)}}},[(item && item.fixed_flg)?_c('div',{staticClass:"fixedPush py-2 px-4",on:{"click":function($event){return _vm.detail(item)}}},[_vm._v(" "+_vm._s(item.title)),_c('v-icon',{attrs:{"color":"#9D9D9D"}},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(item && item.border_flg)?_c('div',{staticClass:"date mb-3 mx-n3"},[_c('div',[_vm._v(_vm._s(item.push_date))])]):_vm._e(),(item && !item.fixed_flg)?_c('Row',{attrs:{"item":item,"router_param":{ name: 'notification_detail', query: {
          id: item.id,
          contents_type: item.contents_type,
          create_date: item.create_date,
          push_date: item.push_date,
          shop_name: item.shop_name,
          shop_id: _vm.init.shop_id
        }}}}):_vm._e()],1)}),_c('infinite-loading',{ref:"infiniteLoading",attrs:{"spinner":"spiral"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('v-progress-circular',{staticClass:"maincolor",attrs:{"size":30,"width":2,"indeterminate":""}})],1),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v("Coming soon")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row class="pb-3" style="background-color:#fff;" @click="detail()">
    <!-- 画像 -->
    <v-col cols="4" class="flex-grow-1 flex-shrink-0" style="box-sizing: border-box;">
      <v-img v-if="item.image_url" :src="item.image_url" lazy-src="@/assets/notification/info_img.jpg" class="rounded" style="border:1px solid #E6E6E6">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
        <div class="new" v-if="item.new_flg">NEW</div>
        <v-chip x-small dark color="#9D9D9D" class="viewflg_label" v-if="item.read_flg === 0">未読</v-chip>
      </v-img>
      <v-img v-else src="@/assets/notification/info_img.jpg" lazy-src="@/assets/notification/info_img.jpg" class="rounded" style="border:1px solid #E6E6E6">
        <div class="new" v-if="item.new_flg">NEW</div>
        <v-chip x-small dark color="#9D9D9D" class="viewflg_label" v-if="item.read_flg === 0">未読</v-chip>
      </v-img>
    </v-col>
    <v-col class="pl-1">
      <!-- アイコン -->
      <v-row class="ma-0" v-if="item.chipsname">
        <v-chip v-if="item.contents_type === 2" x-small label class="mb-2 font-weight-bold type-coupon" style="">{{item.chipsname}}</v-chip>
        <v-chip v-else x-small label class="mb-2 font-weight-bold type-other" style="">{{item.chipsname}}</v-chip>
      </v-row>
      <!-- タイトル -->
      <v-row class="ma-0">
        <v-col class="pa-0 font-weight-bold nl2br mb-2" style="font-size:0.875rem">{{item.title}}</v-col>
      </v-row>
      <!-- 有効期限 -->
      <!-- <v-row class="ma-0 mb-1" v-if="item.start_date || item.end_date">
        <v-col cols="3" class="pa-0 font-weight-bold" style="font-size:0.625rem">
          有効期限
        </v-col>
        <v-col cols="9" class="pa-0" style="font-size:0.625rem">
          <span v-if="item.start_date">{{item.start_date}}</span>〜<br>
          <span v-if="item.end_date">{{item.end_date}}</span>
        </v-col>
      </v-row> -->
      <!-- 対象店舗 -->
<!--      <v-row class="ma-0" v-if="item.shop_name">-->
<!--        <v-col cols="3" class="pa-0 font-weight-bold" style="font-size:0.625rem">-->
<!--          対象店舗-->
<!--        </v-col>-->
<!--        <v-col cols="9" class="pa-0" style="font-size:0.625rem">-->
<!--          {{item.shop_name}}  -->
<!--        </v-col>-->
<!--      </v-row>-->
      
    </v-col>
  </v-row>
</template>

<script>
export default {
    props: {
        router_param: {
            type: Object,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        fixed: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      async detail() {
        // GET パラメータを取得
        this.getUrlParam()
        //createdで、localStorageから全ての保存情報を取得しinitに格納
        this.init = this.storageGet('*')
        if (this.item.contents_type == 1) {// お知らせ
          this.routerBindTo({
            name: 'notification_detail',
            query: {
              push_id: this.item.id,
              contents_type: this.item.contents_type,
              contents_id: this.item.content_id,
              create_date: this.item.create_date,
              shop_name: this.item.shop_name,
            }});
        } else if (this.item.contents_type == 2) { // クーポン
          this.routerBindTo({
            name: 'coupon_detail',
            query: {
              id: this.item.content_id,
              create_date: this.item.push_date,
              prev_route: 'notification_list',
            }});
        // } else if (this.item.contents_type === 7) { // チケット当選
        //     res = await this.apiCall('/user/notification/detail', params, 'get');
        //     if (!res) return false;
        //     this.routerBindTo({name: 'digital_buy', query: {
        //       ticket_id: this.item.content_id,
        //       history_id: res.data.ticket_history_id,
        //       win: true,
        //     }});
        } else {
          this.routerBindTo({
            name: 'notification_detail',
            query: {
              push_id: this.item.id,
              contents_type: this.item.contents_type,
              create_date: this.item.create_date,
              shop_name: this.item.shop_name,
            }});
        }
      },
  }
}
</script>

<style scoped>
.rounded {
  border-radius: 10px;
}
.viewflg_label {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
.type-other {
  color:#FFF;
  background-color:#EB7178 !important;
  border-radius:initial !important;
  width: 7rem;
  display: block;
  text-align: center;
  line-height: normal;
}
.type-coupon {
  color:#FFF;
  background-color:#C39134 !important;
  border-radius:initial !important;
  width: 7rem;
  display: block;
  text-align: center;
  line-height: normal;
}
.new {
  background: #E2333E;
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  padding: 0.2rem 0.25rem;
  z-index: 10;
  border-radius: 1rem;
  color: #fff;
  font-size: 0.5rem;
  font-weight: bold;
  line-height: 1;
}
</style>

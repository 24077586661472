<template>
  <v-content>

<!--    <SelectedShop-->
<!--      v-if="init.branch_flg == 1"-->
<!--      :selected_shop_name="selected_shop"-->
<!--      :router_param="{ name: 'notification_shops', query: { feature_id: 'notification_list'}}" -->
<!--    />-->

    <div class="pa-3">
      <div v-for="item in this.data" :key="(item || {}).id" @click.stop="onClickBtn(item.link)">
        <!-- 固定通知 -->
        <div class="fixedPush py-2 px-4" v-if="item && item.fixed_flg" @click="detail(item)">
          {{item.title}}<v-icon color="#9D9D9D">mdi-chevron-right</v-icon>
        </div>
        <!-- 日付区切り -->
        <div v-if="item && item.border_flg" class="date mb-3 mx-n3">
          <div>{{item.push_date}}</div>
        </div>
        <Row v-if="item && !item.fixed_flg" :item="item" :router_param="{ name: 'notification_detail', query: {
          id: item.id,
          contents_type: item.contents_type,
          create_date: item.create_date,
          push_date: item.push_date,
          shop_name: item.shop_name,
          shop_id: init.shop_id
        }}" >
        </Row>
      </div>

      <!-- InfiniteLoading -->
      <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
        <div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
        <div slot="no-more"></div>
        <div slot="no-results">Coming soon</div>
      </infinite-loading>
    </div>
  </v-content>
</template>

<script>
// import SelectedShop from '@/components/selected_shop.vue'
import Row from '@/views/notification/row.vue'
import InfiniteLoading from 'vue-infinite-loading';
export default {
  components: {
    // SelectedShop,
    InfiniteLoading,
    Row
  },
  data: () => ({
    data: [],
    result: [],
    page: 1,
    // selected_shop: 'すべて',
  }),
  methods: {
    onClickBtn(link) {
      this.$router.push(link)
    },
    async infiniteHandler() {

      this.init = this.storageGet('*')

      // let shop_id = this.getUrlGetParam('shop_id');
      // let selected_shop = this.getUrlGetParam('selected_shop');
      //
      // if (selected_shop) {
      //     this.selected_shop = selected_shop
      // } else {
      //     this.selected_shop = 'すべて'
      // }

      let params = {
        'sid': this.init.sid,
        'device_uid': this.init.device_uid,
        'device_id': this.init.device_id,
        'os': this.init.os,
        'key': this.init.key,
        'location': 0,
        'feature_id': this.init.feature_id,
        'page': this.page,
        'registration_id': this.init.registration_id
      }

      // if (shop_id) {
      //   params['shop_id'] = shop_id
      // }

      let shoplist_req = { params: params }

      let res = await this.apiCall('/user/notifications', shoplist_req)
      if (this.page === 1) {
        this.$set(this, 'result', res)
        this.$set(this, 'data', res.data)
      } else {
        this.data = this.data.concat(res.data);
      }

      this.page = this.page + 1

      if (res.data && res.data.length > 0) {
        this.$refs.infiniteLoading.stateChanger.loaded()
      } else {
        this.$refs.infiniteLoading.stateChanger.complete()
      }

    },
    async detail(item) {
      this.init = this.storageGet('*')
      if (item.contents_type == 1) {// お知らせ
        this.routerBindTo({
          name: 'notification_detail',
          query: {
            push_id: item.id,
            contents_type: item.contents_type,
            contents_id: item.contents_id,
            create_date: item.create_date,
            shop_name: item.shop_name
          }});
      } else if (item.contents_type == 2) { // クーポン
        this.routerBindTo({
          name: 'coupon_detail',
          query: {
            id: item.content_id,
            push_id: item.id
          }});
      } else {
        this.routerBindTo({
          name: 'notification_detail',
          query: {
            push_id: item.id,
            contents_type: item.contents_type,
            create_date: item.create_date,
            shop_name: item.shop_name
          }});
      }
    }
  },
  created() {
    this.getUrlParam()
    this.init = this.storageGet('*')
    // this.selected_shop = this.init.selected_shop
  },
}
</script>

<style scoped>
.fixedPush {
  filter: drop-shadow(0 0 10px #00000033);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 15px;
}
.date {
  background-color: #FFF;
  font-weight: bold;
  padding: 0.25rem 0;
  position: relative;
}
.date>div {
  text-align: center;
  font-size: 0.75rem;
  border: 1px solid #CDCDCD;
  background: #fff;
  border-radius: 1rem;
  width: 60%;
  max-width: 12.5rem;
  margin: 0.25rem auto;
  position: relative;
  z-index: 10;
}
.date::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  border-bottom: 1px solid #CDCDCD;
  z-index: 0;
}
</style>
